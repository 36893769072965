<template>
  <div>
    <topmenu :comname="'dynamic'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>大会动态</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div v-if="isshowlist" class="listinfor" v-loading="loading">
          <ul>
            <li v-for="item in list" :key="item.id">
              <div @click="jumpDteails(item)" class="fl cur">
                <el-image
                  :src="item.vImgUrl?item.vImgUrl:require('@/assets/images/dtmr.jpg')" :alt="item.vImgUrl?item.vName:''"
                  fit="contain"
                  :title="item.vName"
                  style="width:172px; height:104px;"
                ></el-image>
              </div>
              <div class="contm">
                <div class="cur" @click="jumpDteails(item)">
                  <span class="tlew" :title="item.vName">{{item.vName.length>40?comJs.limitNum(item.vName,40):item.vName}}</span>
                </div>
                <div class="lun">
                  {{item.vAbstract!=null&&item.vAbstract.length>80?comJs.limitNum(item.vAbstract,80):item.vAbstract}}
                  <span class="cur" @click="jumpDteails(item)">
                    <span class="red ">[查看更多]</span>
                  </span>
                </div>
                <div class="time">{{item.dCreateTime | formatDate}}</div>
              </div>
            </li>
          </ul>
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination>
        </div>
        <nocont v-else></nocont>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "dynamic",
  data() {
    return {
      list: [],
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 15, //每页显示条数
      totalPage: 0 //总页数
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont
  },
  created() {
    this.getinformation();
  },
  watch: {
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    }
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  },
  methods: {
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    // 页面跳转判断
    jumpDteails(record){
      if(!record.webUrl){
        this.$router.push({
          path: '/dynamicdetails',
          query: {
            id: record.id
          }
        })
      }else{
        window.open(record.webUrl)
        // window.location.href = record.webUrl
      }
    },
    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true
      };
      this.$axios
        .post("/api/app/exhibitionDynamic/getExhibitionDynamicPageList", data)
        .then(res => {
          this.loading = false;
          this.totalPage = res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata);
            this.list = infordata;
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
    }
  }
};
</script>
<style scoped>
.listinfor {
  padding: 40px 0;
}
.listinfor ul {
  display: block;
  overflow: hidden;
  padding: 0 25px;
}
.listinfor li {
  display: block;
  background: #fafafa;
  padding: 15px 10px;
  margin-bottom: 15px;
  overflow: hidden;
}
.t_page {
  margin-top: 30px;
}
.contm {
  text-align: left;
  padding: 0 5px 0 15px;
  float: left;
  width: 760px;
}
.lun {
  line-height: 22px;
  padding: 10px 0;
  color: #666;
  word-break: break-all;
}
.cur{
  cursor: pointer;
}
.tlew {
  font-size: 18px;
  line-height: 22px;
  color: #333;
}
.time {
  font-size: 15px;
  color: #999;
}
</style>